export const Endpoints = {
  baseUrl: "https://topfivebestrated.com/portal/api/",
  imagebaseUrl: "https://topfivebestrated.com/portal/",
  // baseUrl: "http://localhost/sbm/topfive/api/",
  // imagebaseUrl: "http://localhost/sbm/topfive",
  GetCategory: "getCategroy",
  GetCity: "city",
  SearchCity: "search_city",
  SearchCategory: "search_category",
  FindBusiness: "find_business",
  LocalBusiness: "getBusiness",
  searchCityState: "search_city_state",
  GetLocalBusiness: "local-business",
  ContactSave: "contact-save",
  CityBusiness: "city-business",
  GetBlog: "blog",
  GetTopListing: "top-listing",
  ListListing: "list-business/save",
  ListListingNew: "list-business/savenew",
  SaveRating: "review-save",
  SheduleCall: "shedule-call",
  GetAllCity: "all-city",
  GetHomeCity: "home-city",
  SendEnquiry: "send-enquiry",
  
};
