import React, { useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import { CommonAPi } from "../API/CommonApi";
import ToastMessage from "../Utils/ToastMessage";
import { Link, useNavigate } from 'react-router-dom';
const $ = window.jQuery;
export default function EnquiryModal({ businessData, setBusinessData,allitem,address,  myModalenquiry, onSuccess }) {
    const [rating, setRating] = useState('')
    const [name, setName] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [description, setDescription] = useState('')
    const handleRating = (rate) => {
        setRating(rate)
    };

	//console.log(businessData.address);
	const [status, setStatus] = useState(false);

  const imageUrl = process.env.PUBLIC_URL + '/images/logo.png';

    const handleSubmit = async(e) =>{
		setStatus(true);
		
		  let missingParams = [];

		  if (name === "") {
			missingParams.push('Full Name');
		  }

		  if (whatsapp === "") {
			missingParams.push('WhatsApp No');
		  }
			// Check each variable and add to missingParams if empty
			if (description === "") {
				missingParams.push('Message');
			}
		  // Check if there are missing parameters
		  if (missingParams.length > 0) {
			  setStatus(false);
			// Set error message
			ToastMessage.Error(`Please fill in the following required fields: ${missingParams.join(', ')}`);
		  } else {

			const businessName = encodeURIComponent(businessData.name);
			const businessAddress = encodeURIComponent(businessData.address);
			const businessPhone = encodeURIComponent(businessData.phone);
			const businessWebsite = encodeURIComponent(businessData.website_link);
			const businessLocation = encodeURIComponent(businessData.location_url);
			const businessCity = encodeURIComponent(businessData.cityname);
			const businessSubcat = encodeURIComponent(businessData.subcatname);
			const AdminNumber = "8699490011";
			const businessName1 = encodeURIComponent(businessData.name);
			const businessAddress1 = encodeURIComponent(businessData.address);
			const businessPhone1 = encodeURIComponent(businessData.phone);
			const businessmap = encodeURIComponent(businessData.location_url);
			const custom_url = window.location.pathname ;			
			///////////// User
			 
			//user 
			const sendApiUrlUser = `https://watticks.com/WatticksAPI/SendMessage?CustomerId=WSLIYXEOMYXLLXGGQFZSMXLAMYNUJMNJKURMPTRVJMOQBZMRCOSPYJIZGKVZKKFALNBRFT&TemplateName=usersmsg&RecipientMobile=${whatsapp}&Variation=${name},${businessSubcat},${businessCity},${businessName1},${businessPhone1}`;	
			const sendApiUrlAdmin = `https://watticks.com/WatticksAPI/SendMessage?CustomerId=WSLIYXEOMYXLLXGGQFZSMXLAMYNUJMNJKURMPTRVJMOQBZMRCOSPYJIZGKVZKKFALNBRFT&TemplateName=owner&RecipientMobile=${businessPhone}&Variation=${businessName},${name},=${whatsapp},${description},https://topfivebestrated.com/${custom_url}`;
            const sendApiUrlOwner = `https://watticks.com/WatticksAPI/SendMessage?CustomerId=WSLIYXEOMYXLLXGGQFZSMXLAMYNUJMNJKURMPTRVJMOQBZMRCOSPYJIZGKVZKKFALNBRFT&TemplateName=topadmin2&RecipientMobile=${businessPhone}&Variation=${businessName},${name},${whatsapp},${description}`;	
			
			//console.log(sendApiUrl);
		
				fetch(sendApiUrlUser, {
				  method: 'GET',
				})
				  .then(response => {
						setStatus(false);
					if (!response.ok) {
					  //throw new Error('Network response was not ok');
					 // ToastMessage.Error(`Some Technical issue found!`);
						return;
					}
					console.log('response',response);
					return response.json();
				  })
				  .then(data => {
					  setStatus(false);
					// Handle the response data
					//console.log('data',data);
					//ToastMessage.Success("Please check your whatsapp. We sent you all business details.");
									
				  })
				  .catch(error => {
					  setStatus(false);
					// Handle errors
					 // ToastMessage.Error(`Something went wrong!`);
					//ToastMessage.Success("Something went wrong!");
                // Admin
					fetch(sendApiUrlAdmin, {
						method: 'GET'
	  
					  })
						.then(response => {
							  setStatus(false);
						  if (!response.ok) {
							return;
						  }
						  console.log(response);
						  return response.json();
						})
						.then(data => {
							setStatus(false);
						  //console.log(data);
						  //ToastMessage.Error(`good admin!`);
						})
						.catch(error => {
							setStatus(false);
							 //ToastMessage.Error(`Something went wrong admin!`);
                        // Owner
							 fetch(sendApiUrlOwner, {
								method: 'GET',
							  })
								.then(response => {
									  setStatus(false);
								  if (!response.ok) {
									  return;
								  }
								  console.log(response);
								  return response.json();
								})
								.then(data => {
									setStatus(false);
								   //ToastMessage.Success("Please check your whatsapp. We sent you all business details.");
									 console.log(data);
								})
								.catch(error => {
									setStatus(false);
									ToastMessage.Error(`Please check your whatsapp. We sent you all business details.`);
								});
	  
						});

				  });
			
			
			
		
		}
	}
   
  return (
       <div className="modal fade" id={myModalenquiry} tabindex="-1" aria-labelledby="myModalenquiryLabel" aria-hidden="true">

  <div className="modal-dialog">
    <div className="modal-content">

      <div className="modal-header">
        <img src={imageUrl} alt="Your Logo" className="mr-2" style={{height: "30px"}} />
        <h4 className="modal-title mx-auto">Send Enquiry</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div className="modal-body">
        <form>
          <div className="form-group">
            <label for="fullName">Full Name:</label>
            <input type="text" className="form-control" id="fullName" placeholder="Enter your full name" value={name}
                        onChange={(e)=>{
                            setName(e.target.value)
                        }}  />
          </div>
          <div className="form-group">
            <label for="whatsappNumber">WhatsApp Number: +91 </label>
            <input type="text" className="form-control" id="whatsappNumber" placeholder="Enter your WhatsApp number" 
			value={whatsapp}
			onChange={(e)=>{
				setWhatsapp(e.target.value)
			}}   
			/>
          </div>
          <div className="form-group">
            <label for="message">Message:</label>
            <textarea className="form-control" id="message" rows="3" placeholder="Enter your message"
			onChange={(e)=>{
                         setDescription(e.target.value)
                     }} 
			>{description}</textarea>
          </div>
		  <div className="form-group">
            {status ?
				<button
					class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
					type="button"

				>

					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					Loading...
				</button>

				:
				<button
					class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
					type="button"
					onClick={() => {
						handleSubmit()
					}}
				><span class="flex-1 text-center">
						Submit</span>
				</button>
		   }
          </div>
        </form>
      </div>

      <div className="modal-footer">
        <p className="text-left w-100"><strong>Instructions:</strong></p>
		<ol>
        <li className="mr-auto">Please provide your details below, and we'll send you the business details on WhatsApp.</li>
        <li className="mr-auto">This is an enquiry form to check if you're interested in receiving information about the business.
		</li>
		</ol>
      </div>

    </div>
  </div>
</div>
  )
}
