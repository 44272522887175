import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Endpoints } from "../API/Endpoints";

function Header() {
    const location = useLocation();
    return (
        <>
            {/* <div className="preloader-outer">
        <div className="sv-loader">
            <img className="fa-spin" src="images/circle-loader.png" alt="" />
        </div>
    </div> */}

            <header className="navbar navbar-expand-lg sv-navbar sv-navbar-two  d-none d-md-block">
                <div className="container-fluid">
                    <Link className="navbar-brand mt-3" to="/">
                        <strong><img src="images/logo.png" alt="image description" style={{ width: "300px" }} /></strong>
                    </Link>
                    <div className="sv-navbar-right">


                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="fa fa-bars"></span>
                        </button>
                        <nav className="collapse navbar-collapse sv-navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav sv-navbar-nav">
                                <li>
                                    <Link to="/list-business" className="sv-pb ">List Business </Link>
                                </li>

                                <li className="px-2">
                                    <Link to="/find-business">Find Business </Link>
                                </li>

                                <li>
                                    <Link to="/faq">FAQ </Link>
                                </li>
                                {/* <li>
                                    <Link to="/about">About </Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact </Link>
                                </li> */}
                                <li>
                                    <Link to={Endpoints.imagebaseUrl + "login"} target={"_blank"}>Login</Link>
                                </li>
                                <li>
                                    <div id="google_translate_element"></div>
                                </li>


                            </ul>
                        </nav>

                    </div>
                </div>
            </header>

            <header className="navbar navbar-expand-lg sv-navbar sv-navbar-two  d-md-none d-sm-block">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between w-100">
                        <div className="justify-content-start">
                            <Link className="navbar-brand mt-3" to="/">
                                <strong><img src="images/logo.png" alt="image description" style={{ width: "200px" }} /></strong>
                            </Link>
                        </div>
                        <div className="justify-content-end">
                            <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="fa fa-bars"></span>
                            </button>
                        </div>


                    </div>

                    <div className="sv-navbar-right">

                        <nav className="collapse navbar-collapse sv-navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav sv-navbar-nav">
                                <li>
                                    <Link to="/list-business" className="yellow-btn text-white ">List Business </Link>
                                </li>

                                <li >
                                    <Link to="/find-business">Find Business </Link>
                                </li>

                                <li>
                                    <Link to="/faq">FAQ </Link>
                                </li>
                                {/* <li>
                                    <Link to="/about">About </Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact </Link>
                                </li> */}
                                <li>
                                    <Link to="https://topfivebestrated.com/portal/login" target={"_blank"}>Login</Link>
                                </li>
                                <li>
                                    <div id="google_translate_element"></div>
                                </li>


                            </ul>
                        </nav>

                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
